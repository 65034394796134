import React, { useEffect } from "react";
import "./WellNessGrid.css";
import { drips } from "../../data/drips";
import file6 from "../assets/files/Form 6 (Wellness Infusions Order Form).pdf-.pdf";
import { Box, Modal, Typography } from "@mui/material";
import ModalContent from "./ModalContent";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "60%",
  overflowY: "scroll",
  borderRadius: "12px 0px 0px 12px",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "12px", // Add this line
  },
};

const WellNessGrid = () => {
  const [open, setOpen] = React.useState(false);
  const [infusionName, setInfusionName] = React.useState("");
  let navigate = useNavigate();
  const handleOpen = (drip) => {
    // setOpen(true);
    // setInfusionName(drip.title);
    navigate("/wellnessdetail", { state: { drip } });
  };
  const handleClose = () => {
    // setLoader(true);
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              cursor: "pointer",
              color: "#25ABDF",
              fontSize: "35px",
            }}
            onClick={handleClose}
          />
          <ModalContent
            // onClick={handleClose}
            handleClose={handleClose}
            infusionName={infusionName}
          />
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Comming Soon
          </Typography> */}
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
        </Box>
      </Modal>
      <div className="container d-flex flex-column gap-2 my-3">
        <div>
          <p className="grid-title-p">Supporting Infusion Therapy Needs </p>
          <span className="grid-title-span">
            From iron infusions to antibiotics, Executive Infusion provides
            access to customized infusions that help optimize patient
            well-being and health.
          </span>
        </div>

        <div className="container">
          <div className="row mt-2 gy-5">
            {drips.map((drip) => (
              <div className="col-md-4" key={drip.id}>
                <div className="d-flex flex-column gap-3 grid-item-div">
                  <img className="grid-img" src={drip.image} alt="dripPic" />
                  <div>
                    <p className="grid-p">{drip.title}</p>
                    <span className="grid-span">{drip.subtitle}</span>
                  </div>
                  <button className="grid-btn" onClick={() => handleOpen(drip)}>
                    More Info &gt;
                  </button>
                </div>
              </div>
            ))}
            <div className="col-md-4">
              <a
                href={file6}
                download="Wellness_treatment_Form"
                target="_blank"
              >
                <button
                  className={"services-btn-wellness"}
                  style={{
                    width: "auto",
                  }}
                >
                  Download Infusions Order Form{" "}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellNessGrid;
