import React, { useEffect, useState } from "react";
import "../Servicess/services.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate, useLocation } from "react-router-dom";
import file6 from "../assets/files/Form 6 (Wellness Infusions Order Form).pdf-.pdf";
AOS.init();
const Services5 = ({ title }) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile]);
  let navigate = useNavigate();
  const location = useLocation();
  const navigate_ = (url) => {
    navigate(url);
  };

  const url = location.pathname;
  return (
    <>
      <div
        className={` ${isMobile ? "services5-mobile" : "services5_main"}`}
        // className={` ${
        //   url === "/home"
        //     ? isMobile
        //       ? "services5-mobile"
        //       : "services5_main"
        //     : isMobile
        //     ? "WellnwssMobileBgClass"
        //     : "services5-wellness"
        // }`}
      >
        <div
          className="services_data"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          <p
            className="services_heading1"
            // className={` ${
            //   url === "/home"
            //     ? "services_heading1"
            //     : "services_heading-wellness"
            // }`}
            onClick={() => navigate_("/wellness")}
          >
            <span className="highlightedText"> Infusions</span>
          </p>
          <p
            className="services_para1"
            // className={` ${
            //   url === "/home" ? "services_para1" : "services_para-wellness"
            // }`}
          >
            Infuse, Energize , Thrive & Defy Aging
          </p>
          {/* {url === "/home" ? */}
          {!title && (
            <button
              onClick={() => navigate_("/wellness")}
              className="services_btn"
              // className={` ${
              //   url === "/home" ? "services_btn" : "services-btn-wellness"
              // }`}
            >
              Learn More
              {/* {url === "/home" ? "Learn More " : "Book An Appointment"} */}
            </button>
          )}
          {title && (
            <button
              onClick={() => navigate_("/appointment")}
              className="services_btn"
              // className={` ${
              //   url === "/home" ? "services_btn" : "services-btn-wellness"
              // }`}
            >
              {title}
              {/* {url === "/home" ? "Learn More " : "Book An Appointment"} */}
            </button>
          )}

          {/* <a href={file6} download="Wellness_treatment_Form" target='_blank'>
              <button className={"services-btn-wellness"}>Book An Appointment</button>
            </a>} */}
        </div>
      </div>
    </>
  );
};

export default Services5;
